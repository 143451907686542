const seoFields = `
seo {
  title
  canonical
  metaDesc
  metaRobotsNoindex
  metaRobotsNofollow
  opengraphTitle
  opengraphUrl
  opengraphSiteName
  opengraphDescription
  opengraphModifiedTime
  opengraphType
  opengraphImage {
    mediaItemUrl
    mimeType
    mediaDetails {
      width
      height
    }
  }
  schema {
    raw
  }
  twitterImage {
    mediaItemUrl
  }
  twitterTitle
  twitterDescription
}
`;

const featuredImageFields = `
featuredImage {
  node {
    mediaItemUrl
    altText
    title
    srcSet
  }
}
`;

export const postsQuery = `
query getPosts($after: String) {
  posts(after: $after, first: 12) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      databaseId
      title
      slug
      excerpt
      dateGmt
      ${featuredImageFields}
      categories {
        nodes {
          databaseId
          name
        }
      }
    }
  }
}
`;

export const postBySlugQuery = `
query getPostBySlug($slug: String!) {
  postBy(slug: $slug) {
    databaseId
    dateGmt
    title
    categories {
      nodes {
        databaseId
        name
      }
    }
    content(format: RENDERED)
    ${featuredImageFields}
    ${seoFields}
  }
}
`;

export const relatedPostsQuery = `
query ($categoryIds: [ID!], $excludeIds: [ID]) {
  posts(where: {categoryIn: $categoryIds, notIn: $excludeIds}, first: 3) {
    nodes {
      slug
      excerpt
      dateGmt
      title
      databaseId
      categories {
        nodes {
          databaseId
          name
        }
      }
      ${featuredImageFields}
    }
  }
}
`;

export const pageByUriQuery = `
query pageByUri($uri: String) {
  pageBy(uri: $uri) {
    slug
    title
    date
    ${seoFields}
    ${featuredImageFields}
    content(format: RENDERED)
    downloadable {
      file {
        node {
          mediaItemUrl
        }
      }
      image {
        node {
          mediaItemUrl
        }
      }
      title
      linkText
    }
    sections {
      sections {
        headlineTitle
        headlineDescription
        sectionGroup {
          title
          img {
            node {
              mediaItemUrl
            }
          }
          icon {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
}
`;

export const ourCollectionsQuery = `
query {
  page(id: "our-collections", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    sections {
      sections {
        headlineTitle
        headlineDescription
        sectionGroup {
          title
          url
          img {
            node {
              mediaItemUrl
            }
          }
          icon {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
    collections {
      imageSections {
        description
        title
        position
        image {
          node {
            mediaItemUrl
          }
        }
      }
      collage {
        title
        subtitle
        description
        linkLabel
        imagePosition
        link {
          url
        }
        img {
          node {
            mediaItemUrl
          }
        }
      }
      form {
        title
        description
        gravityForm
        image {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
}`;

export const homeQuery = `
query {
  page(id: "home", idType: URI) {
    slug
    title
    date
    videoHeader {
      videoHeader {
        url
      }
    }
    ${seoFields}
    ${featuredImageFields}
    content(format: RENDERED)
    downloadable {
      file {
        node {
          mediaItemUrl
        }
      }
      image {
        node {
          mediaItemUrl
        }
      }
      title
      linkText
    }
    homeTitle {
      title
    }
    bannerImage {
      bannerImage {
        description
        imagePosition
        linkLabel
        subtitle
        title
        link {
          url
        }
        img {
          node {
            mediaItemUrl
          }
        }
      } 
    } 
    sections {
      sections {
        headlineTitle
        headlineDescription
        sectionGroup {
          title
          url
          img {
            node {
              mediaItemUrl
            }
          }
          icon {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
}
`;

export const partnersQuery = `
query {
  pageBy(uri: "partners") {
    title
    ${seoFields}
    ${featuredImageFields}
    partnerLogos {
      partnerLogos {
        name
        img {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
}
`;


export const contactUsQuery = `
query {
  pageBy(uri: "contact-us") {
    slug
    title
    date
    ${seoFields}
    ${featuredImageFields}
    content(format: RENDERED)
    contactForm {
      id
    }
    displayHomes {
      displayHomes {
        name
        image {
          node {
            mediaItemUrl
          }
        }
        contactNumber
        address1
        address2
        openDays
        openHours
        directions
        appointment
      }
    }
    embedMap {
      url
    }
  }
}
`;

export const whereWeBuildQuery = `
query {
  pageBy(uri: "where-we-build") {
    title
    ${seoFields}
    ${featuredImageFields}
    postcodes {
      postcodes {
        code
      }
    }
    embedMap {
      url
    }
  }
}
`;

export const homeDesignsQuery = `
query ($uri:String){
  pageBy(uri: $uri) {
    title
    ${seoFields}
    ${featuredImageFields}
  }
  homeDesigns (first: 200) {
    nodes {
      id
      slug
      title
      homeDesignDetails {
        homeDesignVariations {
          variationName
          bedrooms
          bathrooms
          carSpaces
          storeys
          totalFloorAreaSqm
          landWidth
          houseLength
          houseWidth
          heroImage {
            node {
              srcSet
              mediaItemUrl
            }
          }
          description
        }
        keyFeatures {
          feature
        }
      }
    }
  }
}
`;

export const blogQuery = `
query {
  pageBy(uri: "blog") {
    slug
    title
    date
    ${seoFields}
  }
}
`;


export const headerMenuQuery = `
{
  menu(id: "header-menu", idType: SLUG) {
    id
    name
    menuItems(where: {parentDatabaseId: 0}) {
      nodes {
        uri
        label
        target
        childItems {
          nodes {
            uri
            label
            target
          }
        }
      }
    }
  }
}
`;

export const footerMenuQuery = `
{
  menu(id: "footer-menu", idType: SLUG) {
    id
    name
    menuItems(where: {parentDatabaseId: 0}) {
      nodes {
        uri
        label
        childItems {
          nodes {
            uri
            label
          }
        }
      }
    }
  }
}
`;

export const jamesHardieMenuQuery = `
{
  menu(id: "james-hardie-menu", idType: SLUG) {
    id
    name
    menuItems(where: {parentDatabaseId: 0}) {
      nodes {
        uri
        label
        target
        childItems {
          nodes {
            uri
            label
            target
          }
        }
      }
    }
  }
}
`;

export const knockdownRebuildQuery = `
query {
  page(id: "our-homes/knockdown-rebuild", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    knockdownRebuild {
      enquiryForm {
        title
        gravityForm
        description {
          title
          message
        }
      }
      gallery {
        title
        images {
          nodes {
            mediaItemUrl
          }
        }
      }
      process {
        title
        message
        items {
          title
          message
          image {
            node {
              mediaItemUrl
            }
          }
        }
      }
      buildWithUs {
        title
        message
        image {
          node {
            mediaItemUrl
          }
        }
        link {
          title
          url
        }
      }
      testimonial {
        details {
          title
          description1
          description2
        }
        video {
          url
        }
        comment {
          image {
            node {
              mediaItemUrl
            }
          }
          message
          name
          address
        }
      }
      heroMessage {
        message
        link {
          title
          url
        }
      }
      showcase {
        title
        images {
          before {
            node {
              mediaItemUrl
              title
              altText
            }
          }
          after {
            node {
              mediaItemUrl
              title
              altText
            }
          }
          displayText
        }
      }
      sections {
        title
        message
        image {
          node {
            mediaItemUrl
          }
        }
        link {
          title
          url
        }
      }
      submitForm {
        gravityForm
        title
        description
        image {
          node {
            mediaItemUrl
            altText
            title
          }
        }
      }
    }
  }
}
`;

export const aboutUsQuery = `
query {
  page(id: "about", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    aboutUs {
      heading {
        title
        description
        link {
          url
          title
        }
      }
      video {
        url
      }
      missionVision {
        title
        message
        image {
          node {
            mediaItemUrl
          }
        }
        coreValues {
          title
          values {
            name
          }
        }
      }
      awards {
        title
        description
        slides {
          image {
            node {
              mediaItemUrl
              title
            }
          }
        }
        items {
          achievement
          title
          image {
            node {
              mediaItemUrl
              title
            }
          }
        }
      }
      closingRemarks {
        title
        description
        image {
          node {
            mediaItemUrl
            title
          }
        }
      }
    }
  }
}
`;

export const ourStoryQuery = `
query {
  page(id: "about-us/our-story", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    ourStoryParallax {
      startPage {
        title
        subtitle
      }
      sections {
        year
        title
        description
        images {
          image {
            node {
              mediaItemUrl
              srcSet
              mediaDetails {
                width
                height
              }
            }
          }
        }
      }
    }
  }
}
`;

export const privacyPolicyQuery = `
  query {
    page(id: "privacy-policy", idType: URI) {
      title
      slug
      content
      privacyPolicy {
        policy {
          title
          content
        }
        details {
          title
          image {
            node {
              altText
              mediaItemUrl
              title
            }
          }
          fileLink {
            node {
              link
              mediaItemUrl
            }
          }
        }
      }
      ${seoFields}
      ${featuredImageFields}
    }
  }
`;

export const customProcessQuery = `
query NewQuery {
  page(id: "custom-process", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    customProcess {
      headlineTitle
      headlineDescription
      headlineLink {
        url
        title
      }
      stepsCoverImage {
        node {
          altText
          mediaItemUrl
          srcSet
        }
      }
      process {
        title
        image {
          node {
            altText
            mediaItemUrl
            title
            srcSet
          }
        }
        cardTitle
        cardSubtitle
        list {
          item
        }
        listTitle
        additional
        color
        width
        position
        overlapImage {
          node {
            mediaItemUrl
          }
        }
        overlapImagePosition
        backgroundColor
      }
      contactFormId
      contactFormImage {
        node {
          mediaItemUrl
          altText
          title
          srcSet
        }
      }
      brochure {
        node {
          mediaItemUrl
          link
        }
      }
    }
  }
}
`;

export const customGalleryQuery = `
query NewQuery {
  page(id: "custom-gallery", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    customGallery {
      headlineDescription
      headlineTitle
      headlineLink {
        url
        title
      }
      brochure {
        node {
          mediaItemUrl
          link
        }
      }
      quoteFormId
      quoteFormImage {
        node {
          mediaItemUrl
          link
          altText
          title
        }
      }
      customHomes {
        title
        description
        gallery {
          nodes {
            mediaItemUrl
            altText
            srcSet
            title
          }
        }
      }
    }
  }
}
`;

export const homeBuildersQuery = `
query ($after: String) {
  homeBuilders(after: $after, first: 30) {
    pageInfo {
      hasNextPage
      endCursor
    }
    nodes {
      id
      title
      slug
      homeBuilderDetails {
        coverImage {
          node {
            altText
            mediaItemUrl
            title
            srcSet
          }
        }
        description
        descriptionTitle
        descriptionSubTitle
        details {
          image {
            node {
              altText
              title
              mediaItemUrl
            }
          }
          textGroup {
            description
            title
          }
          imagePosition
        }
        featuredVideo {
          title
          url
        }
        gridFeatures {
          description
          title
          image {
            node{
              altText
              title
              mediaItemUrl
            }
          }
        }
        heading
        subHeading
        quoteFormId
        quoteFormImage {
          node {
            altText
            title
            mediaItemUrl
          }
        }
      }
      ${seoFields}
      ${featuredImageFields}
    }
  }
}`;

export const homeBuilderOptionsQuery = `
  query {
    homeBuilderOptions {
      title: pageTitle
      homeBuilderDetails {
        coverImage {
          node {
            altText
            mediaItemUrl
            title
            srcSet
          }
        }
        description	
        descriptionTitle
        descriptionSubTitle
        details {
          image {
            node {
              altText
              mediaItemUrl
              title
              srcSet
            }
          }
          imagePosition
          textGroup {
            title
            description
          }
        }
        featuredVideo {
          url
          title
        }
        gridFeatures {
          title
          description
          image {
            node {
              altText
              mediaItemUrl
              title
            }
          }
        }
        heading
        subHeading
        quoteFormId
        quoteFormImage {
          node {
            altText
            mediaItemUrl
            title
          }
        }
      }
    }
  }
`;

export const customHomesQuery = `
query {
  page(id: "custom-homes", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    customHomes {
      heading {
        title
        description
        link {
          url
          title
        }
      }
      callouts {
        image {
          node {
            mediaItemUrl
          }
        }
        title
        description
        link {
          url
          title
        }
      }
      gallery {
        title
        link {
          url
          title
        }
        images {
          nodes {
            title
            srcSet
            mediaItemUrl
          }
        }
      }
      topSections {
        title
        position
        description
        image {
          node {
            mediaItemUrl
          }
        }
      }
      project {
        video
        title
        link {
          url
          title
        }
      }
      bottomSections {
        title
        position
        description
        image {
          node {
            mediaItemUrl
          }
        }
      }
      heroMessage {
        message
        link {
          url
          title
        }
      }
      form {
        title
        description
        gravityForm
        description
        image {
          node {
            altText
            title
            mediaItemUrl
            srcSet
          }
        }
      }
    }
  }
}
`;

export const displayHomesQuery = `
query NewQuery {
  page(id: "display-homes", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    displayHomesDetails {
      headlineTitle
      headlineDescription
      categories {
        title
        subtitle
        description
        imagePosition
        image {
          node {
            altText
            mediaItemUrl
            title
          }
        }
        link {
          url
          title
          target
        }
      }
      details {
        title
        description
        image {
          node {
            altText
            mediaItemUrl
            title
          }
        }
        imagePosition
      }
      quoteFormId
      quoteFormImage {
        node {
          altText
          mediaItemUrl
          title
        }
      }
    }
  }
}`;

export const multiDwellingHousesQuery = `
query {
  page(id: "our-homes/multi-dwelling-houses", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    multiDwellingHouses {
      heading {
        title
        description
        link {
          url
          title
        }
      }
      project {
        video
        title
        link {
          url
          title
        }
      }
      services {
        title
        items {
          title
          message
          image {
            node {
              mediaItemUrl
            }
          }
        }
      }
      description {
        title
        message
        image {
          node {
            mediaItemUrl
          }
        }
      }
      gallery {
        title
        link {
          url
          title
        }
        images {
          nodes {
            title
            srcSet
            mediaItemUrl
          }
        }
      }
      messageListing {
        title
        messages {
          title
          message
        }
      }
      form {
        title
        description
        gravityForm
        image {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
}
`;

export const houseAndLandQuery = `
query {
  page(id: "house-and-land-packages", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    houseAndLand {
      enquiry {
        title
        description
      }
      heading {
        title
        description
      }
      sections {
        title
        description
        position
        image {
          node {
            mediaItemUrl
          }
        }
      }
      heroMessage {
        message
        link {
          title
          url
        }
      }
    }
  }
}
`;

export const homeCategoriesQuery = `
query {
  page(id: "home", idType: URI) {
    categories {
      categories {
        title
        url
        img {
          node {
            sourceUrl(size: THUMBNAIL)
            srcSet(size: MEDIUM)
          }
        }
      }
    }
  }
}
`;

export const duplexHousesQuery = `
query {
  page(id: "duplex-houses", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    duplexHouses {
      heading {
        title
        description
      }
      gallery {
        nodes {
          title
          srcSet
          mediaItemUrl
        }
      }
      project {
        title
        link {
          title
          url
        }
      }
      imageSections {
        section {
          title
          position
          content
          image {
            node {
              mediaItemUrl
            }
          }
        }
      }
      hero {
        title
        content
        link {
          title
          url
        }
      }
    }
  }
  homeDesigns (first: 200) {
    nodes {
      id
      slug
      title
      homeDesignDetails {
        homeDesignVariations {
          variationName
          bedrooms
          bathrooms
          carSpaces
          storeys
          totalFloorAreaSqm
          houseLength
          houseWidth
          landWidth
          heroImage {
            node {
              srcSet
              mediaItemUrl
            }
          }
        }
      }
    }
  }
}
`;

export const gtmQuery = `
  {
    googleTagManager {
      googleTag {
        containerTagId
        scripts {
          position
          type
          source
          asChildren
        }
      }
    }
  }
`;

export const homeworldBoxHill = `
  query {
    page(id: "display-homes/homeworld-box-hill/", idType: URI) {
      title
      ${seoFields}
      ${featuredImageFields}
      homeworldBoxHill {
        headlineTitle
        headlineDescription
        details {
          title
          description
          imagePosition
          image {
            node {
              altText
              mediaItemUrl
              title
            }
          }
          link {
            title
            url
          }
        }
        slider {
          title
          images {
            nodes {
              altText
              mediaItemUrl
              title
              srcSet
            }
          }
        }
        sectionTitle
        sectionDescription
        link {
          url
        }
        collage {
          title
          subtitle
          description
          imagePosition
          linkLabel
          img {
            node {
              altText
              srcSet
              mediaItemUrl
              title
            }
          }
          link {
            url
          }
        }
      }
    }
  }
`;

export const homeworldOranParkQuery = `
{
  page(id: "homeworld-oran-park", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    homeworldOranPark {
      header {
        title
        images {
          nodes {
            mediaItemUrl
            srcSet
            title
            altText
          }
        }
      }
      headline {
        title
        description
      }
      details {
        title
        description
        position
        link {
          url
          title
        }
        image {
          node {
            mediaItemUrl
            srcSet
            title
            altText
          }
        }
      }
      sliders {
        title
        images {
          nodes {
            mediaItemUrl
            srcSet
            title
            altText
          }
        }
      }
      section {
        title
        description
        link {
          url
          title
        }
      }
      collage {
        title
        subtitle
        link {
          url
          title
        }
        imagePosition: position
        description
        img: image {
          node {
            mediaItemUrl
            srcSet
            title
            altText
          }
        }
      }
    }
  }
}
`

export const basementDesignQuery = `
  {
    page(id: "basement-design/", idType: URI) {
      title
      ${seoFields}
      ${featuredImageFields}
      basementDesign {
        headlineTitle
        headlineDescription
        formId
        details {
          description
          imagePosition
          title
          image {
            node {
              altText
              srcSet
              title
              mediaItemUrl
            }
          }
        }
        collage {
          title
          subtitle
          linkLabel
          description
          imagePosition
          img {
            node {
              altText
              mediaItemUrl
              title
              srcSet
            }
          }
          link {
            url
          }
        }
      }
    }
  }
`;

export const getHomeDesigns = `
query {
  homeDesigns (first: 300) {
    nodes {
      slug
      title
      ${seoFields}
      ${featuredImageFields}
      quoteForm {
        gravityForm
        title
        message
        image {
          node {
            altText
            title
            mediaItemUrl
            srcSet
          }
        }
      }
      homeDesignHeader {
        headerImage {
          node {
            mediaItemUrl
          }
        }
      }
      homeDesignDetails {
        facades {
          nodes {
            mediaItemUrl
            srcSet
            title
          }
        }
        keyFeatures {
          feature
        }
        homeDesignVariations {
          brochure {
            node {
              title
              mediaItemUrl
            }
          }
          variationName
          description
          storeys
          bedrooms
          bathrooms
          carSpaces
          totalFloorAreaSqs
          totalFloorAreaSqm
          houseLength
          houseWidth
          landWidth
          floorPlan {
            node {
              mediaItemUrl
              srcSet
            }
          }
          floorPlanFlipped {
            node {
              mediaItemUrl
              srcSet
            }
          }
          rooms {
            roomName
            length
            width
          }
          designOptions
          inclusionsLink {
            url
          }
        }
        enquireLink {
          url
        }
      }
    }
  }
}`;

export const selectionStudioQuery = `
query {
  page(id: "display-homes/selection-studio", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    selectionStudios {
      headlineTitle
      headlineDescription
      gallery {
        nodes {
          title
          srcSet
          mediaItemUrl
        }
      }
      callouts {
        title
        description
        image {
          node {
            mediaItemUrl
          }
        }
        link {
          url
          title
        }
      }
      sections {
        title
        description
        position
        image {
          node {
            mediaItemUrl
            title
          }
        }
      }
      heroMessage {
        message
        link {
          title
          url
        }
      }
      form {
        title
        gravityForm
        description
        image {
          node {
            mediaItemUrl
            title
          }
        }
      }
      project {
        video
        title
        link {
          url
          title
        }
      }
    }
  }
}`;

export const dualOccupancyHomesQuery = `
query {
  page(id:"dual-occupancy-homes", idType:URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    dualOccupancyHomes {
      heading {
        title
        description
        link {
          url
          title
        }
      }
      slides {
        title
        images {
          nodes {
            mediaItemUrl
            srcSet
            title
          }
        }
      }
      process {
        title
        slides {
          title
          message
          image {
            node {
              mediaItemUrl
              srcSet
              title
            }
          }
        }
      }
      showcase {
        title
        description
        image {
          node {
            mediaItemUrl
            srcSet
            title
          }
        }
      }
      bottomSection {
        title
        description
        image {
          node {
            mediaItemUrl
            srcSet
            title
          }
        }
        link {
          url
          title
        }
      }
      submitForm {
        image {
          node {
            mediaItemUrl
            title
            srcSet
          }
        }
        gravityForm
        title
        description
      }
    }
  }
}`;

export const discoverStyleQuiz = `
query {
  page(id: "discover-your-style", idType: URI) {
    ${seoFields}
    ${featuredImageFields}
    jhQuiz {
      questions {
        question
        background {
          node {
            mediaItemUrl
            title
          }
        }
        options {
          answer
          designStyle
          points
        }
      }
    }
  }
}`;

export const discoverStyleQuizQuery = `
query {
  page(id: "discover-your-style", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    jhDiscoverYourStyle {
      header {
        title
        subtitle
        link {
          url
          title
        }
        image {
          node {
            mediaItemUrl
            srcSet
          }
        }
      }
      styles {
        title
        description
        image {
          node {
            mediaItemUrl
            srcSet
          }
        }
      }
      quizSection {
        heading
        button
        questions {
          __typename
          ... on JhDiscoverYourStyleQuizSectionQuestionsImageQuestionLayout {
            question
            imageAnswers {
              description
              points
              image {
                node {
                  mediaItemUrl
                  srcSet
                }
              }
            }
          }
          ... on JhDiscoverYourStyleQuizSectionQuestionsTextQuestionLayout {
            question
            image {
              node {
                mediaItemUrl
                srcSet
              }
            }
            textAnswers {
              points
              option
            }
          }
        }
        resultPanel {
          link {
            url
            title
          }
          explore {
            title
            subtitle
            link {
              url
              title
            }
          }
        }
      }
      icons {
        title
        description
        image {
          node {
            mediaItemUrl
            srcSet
          }
        }
      }
    }
  }
}`;

export const exploreRangeQuery = `
query {
  page(id: "explore-the-range", idType: URI) {
    title
    content
    ${seoFields}
    ${featuredImageFields}
  }
}
`;

export const getDesignStyles = `
query {
  jhStyles(where: {orderby: {field: DATE, order: ASC}}) {
    nodes {
      slug
      title
      uri
      content
      ${featuredImageFields}
      jhStylesDetails {
        colorScheme
        maxPoints
        minPoints
        houseDetails {
          title
          description
          bedrooms
          bathrooms
          carSpaces
          size
        }
        bookSection {
          title
          link {
            url
            title
          }
          bookCover {
            node {
              mediaItemUrl
              title
            }
          }
        }
        externalLinks {
          title
          subtitle
          link {
            url
            title
          }
          backgroundImage {
            node {
              title
              mediaItemUrl
            }
          }
        }
        form {
          title
          gravityForm
          description
          image {
            node {
              title
              mediaItemUrl
              altText
            }
          }
        }
        headlineSection {
          title
          description
        }
        styleCharacteristics {
          title
          description
          list {
            listItem
          }
          sliderImages {
            nodes {
              mediaItemUrl
              title
              srcSet
            }
          }
        }
        styleInspiration {
          title
          description
          list {
            listItem
          }
          sliderImages {
            nodes {
              mediaItemUrl
              title
              srcSet
            }
          }
        }
        livingStyleSection {
          description
          image {
            node {
              mediaItemUrl
            }
          }
        }
        lifestyleInclusions {
          description
          list {
            listItem
          }
          sliderImages {
            nodes {
              mediaItemUrl
              srcSet
              title
            }
          }
        }
        brochure {
          description
          title
          link {
            url
            title
          }
          image {
            node {
              mediaItemUrl
            }
          }
        }
        headerText
        headerDescription
        headerImage {
          node {
            mediaItemUrl
            srcSet
          }
        }
      }
    }
  }
}`;

export const lifestyleQuery = `
{
  page(id: "lifestyle", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    lifestyle {
      heading
      kitchenInclusions {
        title
        generalInformation
        slides {
          title
          description
          image {
            node {
              mediaItemUrl
              srcSet
              altText
              title
            }
          }
        }
        image {
          node {
            mediaItemUrl
            title
            srcSet
            altText
          }
        }
        link {
          title
          url
        }
      }
      bathroomInclusions {
        title
        generalInformation
        images {
          image1 {
            node {
              mediaItemUrl
              title
              srcSet
              altText
            }
          }
          image2 {
            node {
              mediaItemUrl
              title
              srcSet
              altText
            }
          }
        }
        link {
          title
          url
        }
        slides {
          title
          description
          image {
            node {
              mediaItemUrl
              srcSet
              altText
              title
            }
          }
        }
      }
      downloadBrochure {
        title
        description
        gravityForm
        image {
          node {
            title
            altText
            mediaItemUrl
            srcSet
          }
        }
        fileLink {
          url
        }
      }
      callouts {
        title
        description
        image {
          node {
            mediaItemUrl
            altText
            srcSet
          }
        }
        link {
          title
          url
        }
      }
      headerImages {
        image1 {
          node {
            mediaItemUrl
          }
        }
        image2 {
          node {
            mediaItemUrl
          }
        }
        image3 {
          node {
            mediaItemUrl
          }
        }
        image4 {
          node {
            mediaItemUrl
          }
        }
      }
    }
    quoteForm {
      gravityForm
      title
      message
      image {
        node {
          altText
          title
          mediaItemUrl
          srcSet
        }
      }
    }
  }
}
`;

export const homeDesignHeaderImageWidget = `
query {
  homeDesignWidgets {
    homeDesignHeader {
      image {
        node {
          mediaItemUrl
        }
      }
    }
  }
}
`;

export const augustQuery = `
{
  page(id: "august", idType: URI) {
    content(format: RENDERED)
  }
}`;

export const mayfairQuery = `
{
  page(id: "mayfair", idType: URI) {
    content(format: RENDERED)
  }
}`;


export const subscribeFormQuery = `
{
  subscribeForm {
    subscribeFormOptions {
      formId
    }
  }
}`;

export const jamesHardieQuery = `
{
  page(id: "james-hardie", idType: URI) {
    title
    ${seoFields}
    ${featuredImageFields}
    jamesHardieLandingPage {
      jhStyles {
        name
        title
        facade
        description
        details {
          car
          lot
          bed
          bath
        }
        image {
          node {
            mediaItemUrl
            srcSet
          }
        }
        features {
          feature
        }
        linkTo {
          title
          url
        }
      }
      headerText
      headerGallery {
        title
        link {
          url
          title
        }
        image {
          node {
            mediaItemUrl
            title
            link
            srcSet
          }
        }
      }
      form {
        title
        gravityForm
        description
        image {
          node {
            mediaItemUrl
            srcSet
          }
        }
      }
      headlineSection {
        title
        description
        link {
          title
          url
        }
      }
      headlineSectionDesign {
        title
        description
        link {
          title
          url
        }
      }
      banner {
        title
        description
        link {
          title
          url
        }
        backgroundImage {
          node {
            mediaItemUrl
          }
        }
      }
      features {
        title
        featureList {
          title
          description
          icon {
            node {
              mediaItemUrl
            }
          }
        }
        image {
          node {
            mediaItemUrl
            title
          }
        }
      }
      bookSection {
        title
        bookCover {
          node {
            title
            mediaItemUrl
          }
        }
        logo {
          node {
            mediaItemUrl
            title
          }
        }
        link {
          url
          title
        }
      }
      imageSection {
        title
        description
        imagePosition
        image {
          node {
            mediaItemUrl
          }
        }
        link {
          title
          url
        }
      }
      externalLinks {
        title
        subtitle
        link {
          url
          title
        }
        backgroundImage {
          node {
            mediaItemUrl
          }
        }
      }
    }
  }
}
`;

export const jamesHardieHomeDesignsQuery = `
{
  homeDesigns (first: 300) {
    nodes {
      id
      slug
      title
      uri
      homeDesignDetails {
        homeDesignVariations {
          variationName
          bedrooms
          bathrooms
          carSpaces
          storeys
          totalFloorAreaSqm
          landWidth
          houseLength
          houseWidth
          heroImage {
            node {
              srcSet
              mediaItemUrl
            }
          }
          description
        }
        keyFeatures {
          feature
        }
      }
      homeDesignCategories(where: {slug: "james-hardie"}) {
        nodes {
          slug
        }
      }
    }
  }
}`;
